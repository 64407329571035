import { subnav } from './subnav';

const hasTransparentNav = document.querySelector(
  '.js-has-transparent-nav',
) as HTMLElement;
const scrollTrigger = 60;

const checkScrollHeight = () => {
  if (window.scrollY >= scrollTrigger) {
    hasTransparentNav.classList.remove('has-transparent-nav');
  } else {
    hasTransparentNav.classList.add('has-transparent-nav');
  }
};

if (hasTransparentNav) {
  checkScrollHeight();

  document.addEventListener('scroll', () => {
    checkScrollHeight();
  });

  if (subnav) {
    hasTransparentNav.classList.add('has-subnav');
  } else {
    hasTransparentNav.classList.remove('has-subnav');
  }
}

const navBtns: NodeListOf<HTMLButtonElement> = document.querySelectorAll(
  '.js-main-nav__link, .js-utility-nav__link',
);

for (const btn of navBtns) {
  btn.disabled = false;
}

// TODO: Add this functionality back in once we can make it more accesseible
//const navItems = document.querySelectorAll('.js-main-nav__item');
//if (navItems.length === navLinks.length) {
//navItems.forEach((navItem, index) => {
//const navLink = navLinks[index];
//navItem.addEventListener('mouseover', () => {
//navLink.setAttribute('aria-expanded', 'true');
//navLink.classList.add('show');
//});

//navItem.addEventListener('mouseleave', () => {
//navLink.setAttribute('aria-expanded', 'false');
//navLink.classList.remove('show');
//});
//});
//}
