(() => {
  'use strict';

  const forms = document.querySelectorAll<HTMLFormElement>(
    '.js-needs-validation',
  );

  Array.from(forms).forEach((form) => {
    form.addEventListener(
      'submit',
      (event: Event) => {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add('was-validated');
      },
      false,
    );
  });
})();
