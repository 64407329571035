import { Carousel } from 'bootstrap';

export const subnav = document.querySelector('.js-subnav');
const subnavLinks =
  document.querySelectorAll<HTMLLinkElement>('.js-subnav-link');
const subnavDropdownBtn = document.querySelector<HTMLButtonElement>(
  'button.js-subnav-dropdown-button',
);

if (subnav) {
  subnavLinks.forEach((link: HTMLLinkElement) => {
    const linkUrl = new URL(link.href);
    if (window.location.pathname === linkUrl.pathname) {
      link.classList.add('active');
      link.setAttribute('aria-current', 'page');
      setButtonText(link.innerText);
    }
  });

  if (window.location.href.includes('connect-with-a-minfraud-expert')) {
    setButtonText('Fraud prevention solutions');
  }

  if (window.location.href.includes('connect-with-a-geoip-expert')) {
    setButtonText('GeoIP solutions');
  }

  if (window.location.href.includes('contact-corrections')) {
    setButtonText('Correct other data');
  }
}

const buyNowBtn = document.querySelector(
  '.js-buy-now-btn',
) as HTMLButtonElement;
const pricingSection = document.querySelector('.js-plans-and-pricing-section');

if (buyNowBtn && pricingSection) {
  buyNowBtn.addEventListener('click', () => {
    pricingSection.scrollIntoView({ behavior: 'smooth' });
    window.location.hash = 'buy-now';
  });

  // Go directly to the Pricing tab
  if (window.location.hash === '#buy-now') {
    const carouselDiv = document.querySelector('#geoip-databases-carousel');
    if (carouselDiv) {
      const carousel = Carousel.getOrCreateInstance(carouselDiv);
      carousel.to(2);
    } else {
      console.error('Cannot find #geoip-databases-carousel');
    }
  }
}

function setButtonText(text: string) {
  if (!subnavDropdownBtn) {
    return;
  }
  subnavDropdownBtn.innerText = text;
}
