const stripLocale = function (pathname: string): string {
  if (pathname.startsWith('/')) {
    pathname = pathname.substring(1); // strip leading slash
  }
  const pathArr = pathname.split('/');
  pathArr.shift(); // removes the locale
  return pathArr.join('/');
};

const leftNav = document.querySelector('.left-nav');

if (leftNav) {
  const pathname = stripLocale(window.location.pathname);

  const leftNavLinks: string[] = [];
  const linkElements = leftNav.querySelectorAll(
    '.left-nav__link',
  ) as NodeListOf<HTMLAnchorElement>;

  for (const link of linkElements) {
    if (link.href !== null) {
      leftNavLinks.push(link.href);
    }
  }

  const activeElements = leftNav.querySelectorAll('.js-left-nav__hasLink');

  for (const link of leftNavLinks) {
    const linkIsActive =
      new RegExp(`^${link}/`).test(pathname) || link === pathname;

    if (linkIsActive) {
      for (const element of activeElements) {
        const anchor = element.querySelector(`a[href="${CSS.escape(link)}"]`);
        if (anchor) {
          element.classList.add('left-nav__link--active');
        }
      }
    }
  }
}
