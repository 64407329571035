window.addEventListener('load', () => {
  // Added a setTimeout so that the Playwright tests
  // have time to use the route.fulfill mocking before
  // the actual endpoint is fetched. This also prevents
  // getSystemStatus from running before page fully loads.
  setTimeout(() => {
    getSystemStatus();
  }, 1000);

  setInterval(() => {
    getSystemStatus();
  }, 30 * 1000);
});

const $headerStatus = <HTMLDivElement>(
  document.querySelector('.js-header__system-status')
);

const setSystemStatus = ($icons: NodeList, message?: string) => {
  const $allSystemStatusIcons = document.querySelectorAll('.status-icon');
  const $headerStatusMessage = <HTMLDivElement>(
    document.querySelector('.js-header__status-message')
  );

  $allSystemStatusIcons.forEach(($systemStatusIcon) => {
    if ($systemStatusIcon) {
      $systemStatusIcon.classList.remove('show-status-icon');
    }
  });

  $icons.forEach((icon) => {
    (icon as Element).classList.add('show-status-icon');
  });

  $headerStatus.classList.add('show-header-system-status');
  if (message !== undefined) {
    $headerStatusMessage.innerText = message;
  } else {
    console.error('message is undefined');
  }
};

const getSystemStatus = async () => {
  const response = await fetch(
    'https://status.maxmind.com/1.0/status/53fcfbb2ac0c957972000235',
  );
  const json = await response.json();

  const customIncident = json.result.incidents;
  const customMaintenance = json.result.maintenance.active;
  const systemStatusCode = json.result.status_overall.status_code;

  const $warningIcons = document.querySelectorAll('.status-icon__warning');

  if (response.ok) {
    if (customIncident.length != 0) {
      setSystemStatus(
        $warningIcons,
        `${json.result.status_overall.status}: ${json.result.incidents[0].name}`,
      );
    } else if (customMaintenance.length != 0) {
      setSystemStatus(
        $warningIcons,
        `${json.result.status_overall.status}: ${json.result.maintenance.active[0].name}`,
      );
    } else {
      // https://kb.status.io/developers/status-codes/
      switch (systemStatusCode) {
        case 100:
          return;
        case 200:
          setSystemStatus(
            $warningIcons,
            'Planned Maintenance: We are currently undergoing some ' +
              'scheduled maintenance.',
          );
          break;
        case 300:
          setSystemStatus(
            $warningIcons,
            'Degraded Performance: We are currently experiencing ' +
              'degraded performance in some of our web services.',
          );
          break;
        case 400:
          setSystemStatus(
            $warningIcons,
            'Partial Service Disruption: Some of our web services ' +
              'are temporarily unavailable.',
          );
          break;
        case 500:
          setSystemStatus(
            $warningIcons,
            'Service Disruption: Our web services are temporarily ' +
              'unavailable.',
          );
          break;
        case 600:
          setSystemStatus(
            $warningIcons,
            'Security Event: We are currently mitigating issues ' +
              'relating to some of our web services.',
          );
          break;
      }
    }
  } else {
    throw new Error('There was an error retrieving the status.');
  }
};
